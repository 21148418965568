@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inventory-product-wrapper {
  .not-found {
    text-align: center;
    padding: 2rem;
    opacity: 0.6;
  }
  .inventory-product-content {
    display: grid;
    grid-template-columns: max-content 335px 120px;
    gap: 1rem;

    .invalid-feedback {
      position: absolute;
    }

    @media only screen and (max-width: 992px) {
      grid-template-columns: max-content 1fr 120px;
      width: 100%;

      @media only screen and (max-width: 480px) {
        display: flex;
        flex-wrap: wrap;
        gap: 0;

        .col {
          flex: initial !important;

          &:first-child {
            width: 80px;
            margin-right: 0.5rem;

            @media only screen and (max-width: 415px) {
              margin-right: 1rem;
            }
          }

          &:nth-child(2) {
            width: 70%;
          }

          &:last-child {
            width: 70%;
            margin: 0 37px 0px auto;

            @media only screen and (max-width: 415px) {
              width: 100%;
              margin: 0 auto;
            }
          }
        }
      }
    }

    .form-label {
      color: #0e3c55;
      font-size: 1em;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      margin-bottom: 0.7rem;
    }

    .form-control {
      border-radius: 4px;
      border: 1px solid #0e3c55;
      background: #fff;
      height: 46px;
      color: #0e3c55;
      font-weight: 500;
      font-style: normal;
      font-weight: 500;
      line-height: 10px;
      letter-spacing: 1.44px;
      font-size: 1em;
      transition: all 0.3s ease-in-out;

      &:focus {
        border-color: #f26d24;
        transition: all 0.3s ease-in-out;
      }

      &::placeholder {
        color: #979797;
        font-size: 0.875em;
        font-style: normal;
        font-weight: 400;
        line-height: 10px;
      }
    }

    figure {
      margin: 0;

      img {
        max-width: 100%;
      }
    }

    .btn-orange {
      width: 100%;
      height: 46px;
      margin-top: 2rem;
      color: #fff;
      text-align: center;
      font-size: var(--font-size-12);
      font-weight: 600;
      line-height: 14px;
      text-transform: capitalize;
    }
  }

  &.small {
    .form-control {
      height: 35px;
    }
    button {
      height: 35px !important;
      margin-top: 30px !important;
    }
    img {
      height: 80px;
    }
  }

  // inventory-product-wrapper
  .inventory-product-wrapper {
    width: 100%;

    .product-header {
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        margin: 2rem 0 1.1rem;
        padding: 0;

        li {
          list-style-type: none;
          display: inline-block;
          gap: 0.5rem;
          color: #0e3c55;
          font-size: var(--font-size-13);
          font-style: normal;
          line-height: 10px;

          strong {
            font-weight: 600;
          }

          span {
            font-weight: 500;
          }
        }
      }
      .add-product-h-list {
        li {
          padding-right: 15px;
          span {
            font-weight: 400;
          }
        }
      }
    }

    .product-list-wrapper {
      height: 250px;
      overflow-y: auto;
      overflow-x: hidden;
      @media only screen and (max-width: 767px) {
        height: 209px;
      }

      .product-list-content {
        display: grid;
        grid-template-columns: 70px 1fr;
        gap: 1rem;
        border-radius: 8px;
        border: 1px solid #e1e3e6;
        background: #fff;
        box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.05);
        margin-bottom: 0.7rem;
        padding: 1rem;
        margin-right: 0.5rem;

        figure {
          width: 60px;
          height: 60px;
          flex-shrink: 0;
          border: 1px solid #dee2e6;
          background: #f9f9f9;
          @include flex;

          img {
            width: 52px;
            height: 52px;
            flex-shrink: 0;
            object-fit: cover;
          }
        }

        .product-right-list {
          h2.title {
            color: #0e3c55;
            font-size: 1em;
            font-style: normal;
            font-weight: 600; //; actual figam is showing 500 but as per expected we are not getting weight style;
            line-height: 20px;
          }

          .product-list-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1rem;

            @media only screen and (max-width: 768px) {
              grid-template-columns: 1fr;
            }

            ul {
              margin: 0;
              padding: 0;

              li {
                list-style-type: none;
                color: #4c5258;
                font-size: var(--font-size-13);
                font-style: normal;
                line-height: 27px;
                display: grid;
                grid-template-columns: 125px 1fr;

                strong {
                  font-weight: 600;
                }

                span {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}

// inventory-product-modal-wrapper---start--here--->>>>>>

.inventory-product-modal-wrapper {
  .product-modal-content-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;

    @media only screen and (max-width: 559px) {
      grid-template-columns: repeat(2, 1fr);

      @media only screen and (max-width: 320px) {
        grid-template-columns: 1fr;
      }
    }

    .select-wrap {
      label {
        color: #0e3c55;
        font-size: 0.875em;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0.7rem;
      }

      select {
        border-radius: 4px;
        border: 1px solid #0e3c55;
        height: 40px;
        width: 100%;
      }
    }
  }

  .modal-footer {
    .device-btn {
      @include flex;
      width: 194px;
      height: 40px;
      padding: 12px 24px;
      border-radius: 8px;
      background: #b7bdc6;
      border-color: transparent;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 0px 24px 0px rgba(237, 241, 244, 0.3);

      &:hover {
        opacity: 0.78;
        background: #b7bdc6;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .product-modal-grid-wrap {
    height: calc(40vh - 10px);

    @media only screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      height: max-content;
    }

    .product-modal-sub-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
      }

      margin: 2rem 0 0;
      gap: 0.5rem;
      .media {
        display: flex;
        justify-content: center;
        img {
          width: fit-content;
          max-width: 100%;
          overflow: hidden;
          max-height: 38vh;
        }
      }
      .product-modal-device-type {
        margin-left: 1rem;
        .device-type-item {
          h2 {
            color: #000;
            font-size: var(--font-size-12);
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
          }

          ul {
            @include flex;
            justify-content: left;
            grid-gap: 0.5rem;
            flex-wrap: wrap;
            margin: 0 0 1rem;
            padding: 0;

            li {
              list-style-type: none;
              border-radius: 3px;
              border: 1px solid #0e3c55;
              background: #fff;
              display: inline-flex;
              padding: 6px 9px;
              align-items: flex-start;
              gap: 10px;
              color: #0e3c55;
              text-align: center;
              font-size: var(--font-size-12);
              font-style: normal;
              font-weight: 400;
              line-height: 15.5px;
              letter-spacing: -0.12px;
              text-transform: capitalize;

              &.active {
                background: #0e3c55;
                color: #fff;
              }
            }
          }
        }

        .device-value {
          p {
            color: #0e3c55;
            font-size: var(--font-size-14);
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            span {
              color: #f26d24;
              font-size: var(--font-size-16);
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}

// inventory-product-modal-wrapper---end--here--->>>>>>

// add Product Modal
.add-product-modal {
  .modal-dialog {
    .modal-content {
      @media (min-width: 1400px) {
        max-width: 750px;
        height: 685px;
      }
      .scan-btn-style {
        @media (min-width: 1400px) {
          width: 92px;
        }
      }
    }
  }
}
