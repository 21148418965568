.btn {
  box-shadow: 0px 0px 24px 0px rgba(237, 241, 244, 0.3);
  font-size: var(--font-size-12);
  font-weight: 600;
  border: none;
  border-radius: 4px;

  &.btn-orange {
    background-color: #f26d24;
    color: #ffffff;
    border: 1px solid #f26d24;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: #cd5615 !important;
      border: 1px solid #cd5615 !important;
      box-shadow: none;
      color: #ffffff;
    }
  }
  
  &.btn-blue {
    background: #0e3c55;
    border: 1px solid #0e3c55;
    color: #fefefe;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;    
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: #014b72 !important;
      box-shadow: none;
      border: 1px solid #014b72 !important;
    }
    &:disabled {
      background-color: #014b72 !important;
      box-shadow: none;
      color: #ffffff;
      cursor: no-drop;
    }
  }

  &.btn-gray {
    background-color: #9e9e9e;
    color: #fff;
    border: 1px solid #9e9e9e;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;    
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: #4b4a4a !important;
      border: 1px solid #4b4a4a !important;
    }
  }

  &.btn-light-blue {
    background-color: #1896ba;
    color: #fff;
    border: 1px solid #1896ba;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;    
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: #3caecd !important;
      border: 1px solid #0f9ec6 !important;
    }
    &.inactive {
      background-color: #ffffff;
      border: 1px solid #0f9ec6;
      color: #0f9ec6;
      &:hover,
    &:focus,
    &:active,
    &:focus-visible {
        background-color: #0f9ec6 !important;
        color: #ffffff;
        border: 1px solid #0f9ec6 !important;
      }
    }
  }

  &.btn-trans {
    background-color: white;
    color: #0e3c55;
    border: 1px solid #0e3c55;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;    
    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      color: #0e3c55 !important;
      background-color: $gray-100 !important;
      border-color: #0e3c55 !important;
      box-shadow: none;
    }
    &:disabled {
      background-color: #2b6382 !important;
      box-shadow: none;
    }
    &.orange {
      border: 1px solid $orange;
      color: $orange !important;
      &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      color: $orange !important;
      background-color: $gray-100 !important;
      border-color: $orange !important;
      box-shadow: none;
    }
    }
  }
  &.red-btn {
    background-color: #c75e5e;
    color: #fff;
    border: 1px solid #c75e5e;
    min-width: 114px;
    height: 35px;
    padding: 5px 10px;    
    &:hover,
    &:focus,
    &:focus-visible {
      background-color: #f52121;
    }
  }
  &.btn-close {
    min-width: auto;
    height: auto;
  }

  &.disabled {
    opacity: .6;
  }
}


.disabled {
  cursor: no-drop !important;
  pointer-events: all !important;
}