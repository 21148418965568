:root {
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-13: 13px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
}

@media screen and (min-width: 768px) {
  :root {
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-15: 15px;
    --font-size-16: 16px;
    --font-size-18: 18px;
    --font-size-20: 20px;
  }
}

@media screen and (min-width: 1800px) {
  :root {
    --font-size-11: 13px;
    --font-size-12: 14px;
    --font-size-13: 15px;
    --font-size-14: 16px;
    --font-size-15: 17px;
    --font-size-16: 18px;
    --font-size-18: 20px;
    --font-size-20: 22px;
  }
}

// Here you can add other styles
.main-container {
  background-image: linear-gradient(to right, #f6f8fa, #e9f1fc);
  .header {
    background-image: linear-gradient(to right, #f6f8fa, #e9f1fc) !important;
    padding-left: 0px !important;
  }
}
@media (max-width: 420px) {
  .header-brand {
    padding-top: 0px;
    img {
      max-width: 150px;
    }
  }
}
.sidebar-brand-full {
  width: 80%;
}

.form-check-input {
  cursor: pointer;
  //box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: unset;
}

input[readonly] {
  background-color: $gray-100 !important;
}
.back-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-decoration: auto;
}

// date picker
.react-datepicker__input-container {
  input {
    cursor: pointer;
    height: 36px;
    border-radius: 4px;
    color: #000;
    font-size: var(--font-size-14);
    font-style: normal;
    display: block;
    width: 100%;
    min-width: 240px;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    background-color: var(--cui-input-bg, var(--cui-body-bg));
    background-clip: padding-box;
    border: var(--cui-border-width) solid #ccc;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  input:hover,
  input:focus-visible {
    box-shadow: none;
    border: 1px solid #f26d24;
  }
}

.link {
  text-decoration: underline;
  color: #1995ba !important;
}

.sidebar-nav {
  margin-top: 50px;
  .simplebar-content-wrapper {
    padding: 0px 20px;
  }
  .nav-item {
    height: 46px;
  }
  .nav-group {
    margin-bottom: 5px;
  }
  .nav-group-items {
    .nav-item {
      height: auto;
      .nav-link {
        padding: 6px 0px;
      }
    }
  }
  .nav-item,
  .nav-group {
    width: 100%;
    //margin: 0px 20px;
    .nav-link {
      border-radius: 11px !important;
    }
  }
  .nav-group {
    border-radius: 11px !important;
    .nav-link {
      display: flex;
      justify-content: space-between;
      .notification-count {
        font-size: 9px;
        background-color: #f26d24;
        color: #ffffff;
        width: 50px;
        font-weight: bold;
        height: 20px;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
      }
    }
    .nav-text {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
    }
  }
  .nav-group.show {
    border-radius: 11px !important;
    .nav-group-toggle::after {
      background-image: var(--cui-sidebar-nav-group-indicator-hover);
    }
    .nav-link {
      color: #ffffff;
      &.active {
        color: #ffffff;
        //background: #32b9e1;
      }
    }
  }
}

.container-fluid {
  padding-left: 0px !important;
  @media (max-width: 650px) {
    padding: 0px !important;
  }
}

.container-lg {
  max-width: 100%;
  padding-left: 0px !important;
  padding-right: 20px !important;
  @media (max-width: 768px) {
    padding-left: 10px !important;
    padding-right: 10px !important;
    .card-body {
      padding: 15px 10px;
    }
  }
}

.card {
  box-shadow: 0px 0px 20px 0px #b1c5e01a;
  .card-title {
    color: #0e3c55;
    font-weight: 600;
    font-size: var(--font-size-15);
  }
}

.icon_down_dir {
  right: 12px !important;
  &:before {
    content: '' !important;
  }
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-27 {
  margin-top: 27px;
}

input[type='checkbox'] {
  width: 14px;
  height: 14px;
  border-radius: 1px;
  border: 1px solid #b7bdc6 !important;
  margin-top: 0;
  box-shadow: none !important;
  
  &:hover,
  &:focus-visible {
    box-shadow: 0 0 0 2px rgba(50, 31, 219, 0.25) !important;
  }
  &:checked {
    border: 1px solid #1995ba !important;
    background-color: #1995ba !important;
  }
}

.sidebar-image-container {
  margin-right: 15px;
  //width: 25px;
}

.sidebar-narrow-unfoldable {
  .simplebar-content-wrapper {
    padding: 0px 12px;
    .nav-item,
    .nav-group {
      width: 42px;
      .nav-text {
        display: none;
      }
    }
    .nav-group {
      .nav-text {
        margin-left: 7px;
      }
    }
  }
  &:hover {
    .simplebar-content-wrapper {
      padding: 0px 20px;
      .nav-item,
      .nav-group {
        width: 235px;
        .nav-text {
          display: block;
        }
      }
      .nav-group {
        .nav-text {
          display: flex !important;
          justify-content: space-between;
        }
      }
    }
  }
}

.nav-text {
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.nav-group-items {
  .nav-link {
    color: #a6dbeb !important;
    &.active {
      color: #ffffff !important;
    }
  }
  .nav-item {
    &:last-child {
      padding-bottom: 10px;
    }
  }
  .nav-text {
    display: block !important;
    width: auto !important;
  }
}

.header-nav {
  color: #0e3c55;
  font-size: var(--font-size-15);
  font-style: normal;
  font-weight: 500;
  .avatar {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    background-color: #0e3c55;
    color: #fff;
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: 600;
    margin-right: 10px;
    text-transform: uppercase;
  }
  img {
    margin-left: 10px;
  }
  .user-menu .dropdown-menu {
    padding: 0px;
    .dropdown-item {
      padding: 10px 15px;
    }
  }
  .dropdown.show {
    position: relative;
    &::before {
      position: absolute;
      content: '';
      right: 6px;
      bottom: -12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 10px solid #ffffff;
    }
  }
  .dropdown-menu {
    color: #0e3c55;
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: 400;
    border: none;
    position: relative;
    margin-top: 4px !important;
    &.notification-block {
      margin-top: 12px !important;
      padding: 0px;
    }
  }
  .badge {
    position: absolute;
    background-color: #f26d24;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    color: #fff;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    right: -2px;
    top: -5px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.toast {
  color: #ffffff;
  z-index: 10000;
  width: 400px;
  max-width: 100%;
  .t-container {
    padding: 20px 14px;
    position: relative;
    img {
      margin-right: 15px;
    }
    .toast-body {
      padding: 0px;
    }
    .toast-head {
      font-size: var(--font-size-16);
      line-height: 16px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    .toast-message {
      font-weight: 400;
      font-size: var(--font-size-14);
      line-height: 16px;
      color: #ffffff;
      width: 280px;
    }
    button {
      padding: 0;
      background: transparent;
      border: none;
      position: absolute;
      right: 15px;
      top: 15px;
      img {
        margin: 0;
      }
    }

    /*&.success {
            background: #101257;
        }
        &.failure {
            background: #C23933;
        }
        .toast-icon {
            margin-right: 30px;
        }
        */
  }
}

.loader-container {
  position: fixed;
  background: #c0cfd375;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-border {
  color: $theme-color;
}
.header-button-container {
  text-align: right;
  display: flex !important;
  justify-content: flex-end;
  gap: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.modal {
  .modal-title {
    color: #0e3c55;
    font-size: var(--font-size-20);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .modal-body {
    padding: 25px 20px;
  }
}

.disable_ms {
  background-color: #d9dbdf;
}

.form-label {
  color: #000;
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
}
.form-control,
.form-select {
  height: 35px;
  border-radius: 4px;
  color: #000;
  font-size: var(--font-size-14);
  font-style: normal;
  font-weight: 500;
  &::placeholder {
    // / Chrome, Firefox, Opera, Safari 10.1+ /
    color: #9f9f9f;
    font-size: var(--font-size-14);
    line-height: normal;
    font-style: normal;
    font-weight: 300;
    // opacity: 1; / Firefox /
  }

  &:-ms-input-placeholder {
    // / Internet Explorer 10-11 /
    color: #9f9f9f;
    line-height: normal;
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: 300;
  }

  &::-ms-input-placeholder {
    // / Microsoft Edge /
    color: #9f9f9f;
    line-height: normal;
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: 300;
  }
  &:hover,
  &:focus-visible {
    box-shadow: none;
    border: 1px solid #f26d24;
  }
}

#search-sell {
  &::placeholder {
    font-size: var(--font-size-22);
  }
  &:-ms-input-placeholder {
    font-size: var(--font-size-22);
  }
  &::-ms-input-placeholder {
    font-size: var(--font-size-22);
  }
}

.form-check {
  margin-bottom: 6px;
  .form-check-input {
    margin-top: 7px;
  }
}

.form-check-label {
  color: #4c5258 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

h6.checkbox-heading {
  color: #4c5258;
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: 700;
  margin-bottom: 12px;
}

.confirmation-modal {
  .modal-body {
    padding: 0px 40px 46px;
  }
  .confirmation-icon {
    margin-bottom: 26px;
  }
  .confirmation-text {
    color: #0e3c55;
    font-size: var(--font-size-20);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  .confirmation-desc {
    color: #4c5258;
    text-align: center;
    font-size: var(--font-size-12);
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  .confirmation-buttons {
    display: flex;
    gap: 15px;
    button {
      width: 90px;
      height: 28px;
      padding: 0px;
      min-width: 90px;
    }
  }
}

.container-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 50vh;
  &.error-message {
    height: auto;
    margin-top: 100px;
  }
  @media (max-width: 767px) {
    height: calc(50vh - 70px - 1.5rem);
  }
  .heading {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    background: linear-gradient(180deg, #1995ba, #101257 100%);
    background: -webkit-linear-gradient(180deg, #1995ba, #101257 100%);
    -webkit-text-fill-color: transparent;
  }
  .sub-heading {
    font-size: 60px;
    font-weight: 600;
    //background: linear-gradient(180deg, #1995ba, #101257 100%);
    //background: -webkit-linear-gradient(180deg, #1995ba, #101257 100%);
    text-align: center;
    @media (max-width: 500px) {
      font-size: 35px;
    }
  }
}
.side-image img {
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.highlight {
  color: #1995ba !important;
  &.option {
    color: unset !important;
  }
}

.searchWrapper {
  padding-left: 10px !important;
  padding-right: 30px !important;
  overflow: auto;
}


.nav-header-logo {
  max-width: 220px;
  @media (max-width: 500px) {
    max-width: 180px;
  }
}
.btn-white-color {
  color: #ffffff;
}

.input-text-font-weight {
  font-weight: 400;
}

.border-none {
  border: none;
}

.center-v-h {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-0 {
  padding: 0;
}

.flex-direction-column {
  flex-direction: column;
}
.cursor-pointer {
  cursor: pointer;
}

// multiSelect----start---here->>>
.multiselect-container {
  .search-wrapper {
    min-height: 35px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 5px;
    font-size: 11px;
    input {
      margin: 0;
    }
    .chip {
      border-radius: 4px;
      background: #e3f0f3;
      color: #6d7581;
      line-height: 0;
      padding: 2px 6px;
      margin-bottom: 0;
      img {
        filter: brightness(0.5);
        width: 12px;
        height: 12px;
      }
    }
  }
  .optionListContainer {
    .optionContainer {
      li {
        font-size: var(--font-size-14) !important;
        display: flex;
        align-items: center;
        input[type='checkbox'] {
          margin-top: 0;
          &:checked {
            border-color: red;
            background-color: red;
          }
        }
      }
      .highlightOption {
        background: transparent !important;
      }
    }
  }
}

.required {
  color: #f26d24;
}

.permission-checks {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 8px;
  .checkbox-heading {
    margin-bottom: 5px;
  }
}

.custom-selector {
  position: relative;
  .arrow {
    position: absolute;
    top: 48px;
    max-width: 12px;
    max-height: 12px;
    left: 222px;
  }
  .form-control {
    padding-right: 30px;
  }
  .search-box {
    .not-found {
      padding-right: 15px;
      padding-top: 10px;
    }
    .icon {
      color: #000;
      padding-left: 5px;
      margin: 0 !important;
    }
    p {
      cursor: pointer;
      margin-bottom: 0;
      font-size: 14px;
      padding: 5px;
      padding-left: 20px;
      font-weight: 400;
      width: 100%;
    }
    p:hover {
      background-color: #dbd7d7;
      border-radius: 3px;
    }
    .selected {
      background-color: #dbd7d7;
      margin: 5px 0;
      border-radius: 3px;
      p {
        padding-left: 0;
      }
    }
    overflow: auto;
    color: #000;
    position: absolute;
    top: 70px;
    max-width: 250px;
    max-height: 250px;
    min-height: 50px;
    border: 1px solid rgb(123, 122, 122);
    border-radius: 5px;
    background-color: rgba(250, 250, 250, 1);
  }
}

.address {
  font-size: var(--font-size-12);
  font-weight: 400;
  margin-right: 5px;
  color: #6d7581;
}

.bg-dark {
  background-color: #e3f0f3 !important;
}

.icon {
  color: #6d7581;
  cursor: pointer;
}

/*::-webkit-scrollbar {
  display: none  !important;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background: #d0d0d0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #1995ba;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  opacity: 0.77;
}*/

// multiSelect----end---here--->>>
@media (max-width: 767px) {
  .remove-on-mobile {
    display: none;
  }
  .show-on-mobile {
    padding-left: 10px;
  }
  .header-sticky {
    margin-bottom: 10px !important;
  }
  #traffic {
    margin-bottom: 10px !important;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .show-on-mobile {
    display: none;
  }
}


.notification-block {
  transform: translate(14px, 24.6667px) !important;
  width: 22vw;
  max-height: 70vh;
  overflow-y: auto;
  li {
    padding: 12px 15px;
    font-size: var(--font-size-12);
    border-bottom: 1px solid #e4e6ea;
    cursor: pointer;
    &:hover {
      background-color: #ebedef;
    }
    a.dropdown-item {
      padding: 0;
    }
    &:last-child {
      border-bottom: none;
    }
    .time-ago {
      font-size: 10px;
      color: #979797;
    }
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  figure {
    max-width: max-content;

    @media only screen and (max-width: 767px) {
      display: flex;

      img {
        max-width: 70% !important;
        margin: 0 auto;
      }
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }
}

.loading-btn {
  span {
    .spinner-grow {
      margin-right: 12px;
      width: 10px;
      height: 10px;
    }
  }
}

.ql-toolbar {
  border-radius: 4px 4px 0 0;
}

.ql-container {
  border-radius: 0 0 4px 4px;
}

.ql-editor {
  min-height: 175px;
  max-height: 500px;
  overflow: auto;
}

.clickable {
  color: #1895ba !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #f26d24 !important;
  }
}

.text-capitalize {
  text-transform: capitalize;
  option {
    text-transform: capitalize;
  }
}

.header-border-bottom {
  border-bottom: 1px solid #e4e6ea;
  padding-bottom: 25px;
}

.page-container {
  padding-top: 25px;
}

.wrapper-header {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  button {
    border: none;
    background: transparent;

    &:focus-visible {
      outline: none;
    }
  }
}

.field-with-loader {
  position: relative;
  .spinner-border {
    position: absolute;
    right: 5px;
    bottom: 6px;
  }
}

.product-image {
  border: 1px solid #dee2e6;
  background: #f9f9f9;
  max-width: 90px;
  width: 90px;
  height: 90px;
  overflow: hidden;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  &.small {
    width: 50px;
    height: 50px;
  }
  div {
    display: contents;
  }
  img {
    max-height: 95%;
    max-width: 95%;
  }
  &.large {
    max-width: 140px;
    img {
      max-height: 80%;
    }
  }
  &.margin-0 {
    margin-right: 0;
  }
  .product-asset-image {
    width: 63px;
    max-height: 63px;
    @media (max-width: 750px) {
      overflow: visible;
      margin-bottom: 1rem;
    }
  }
}

.image-container {
  width: 700px;
  max-width: 100%;
  .dropzone {
    width: 700px;
    max-width: 100%;
    cursor: pointer;
    height: 90px;
    border: 1px dashed #b7bdc6;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
    &.image-uploaded {
      height: auto;
      .uploaded-file {
        border: 1px solid var(--cui-border-color);
        border-radius: 4px;
        min-width: 100px;
        min-height: 100px;
        padding: 4px;
        background: $gray-100;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: auto;
          width: auto;
          max-width: 100%;
          max-height: 100px;
        }
      }
    }
    .error {
      color: red !important;
    }
    div {
      margin: 0;
      font-size: 14px;
      font-style: normal;
      color: #6d7581;
      font-weight: 400;
      &.light {
        color: #b7bdc6;
        font-size: 12px;
      }
    }
  }
  &.small {
    width: 100%;
    height: 300px;
    .dropzone {
      width: 100%;
      height: 300px;
      .product {
        width: 80%;
        height: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
    }
  }
  &.xsmall {
    position: relative;
    width: 100%;
    height: 100px;
    .dropzone {
      width: 100%;
      height: 100px;
    }
    .product {
      position: absolute;
      height: 70px;
      left: 20px;
      top: 14px;
      margin: 0;
      max-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  }

  &.vertical-preview {
    // width: 100%;
    // height: 300px;
    .dropzone {
      // width: 100%;
      height: auto;
      .product {
        width: 80%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }
    }
  }
}

.rc-time-picker-input {
  height: 44px !important;
  color: #000 !important;
  font-size: var(--font-size-14) !important;
}
.rc-time-picker-panel-input-wrap {
  height: 50px;
}

.default-dropdow-view {
  .search-wrapper {
    .chip {
      margin: 0;
      background: transparent;
      padding: 0;
      font-size: 14px;
    }
  }
  &.single-select {
    .closeIcon {
      display: none;
    }
  }
}

.multiSelectContainer li:hover {
  background: $theme-color !important;
}

.img-box-large {
  width: 150px;
  height: 150px;
  background-color: #f4f7fa;
  border: 1px solid gray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: auto;
    height: auto;
    max-height: 80%;
    max-width: 80%;
  }
}

.min-height-accordion {
  .accordion-header {
    button {
      padding: 10px;
    }
  }
}

.gallery-images {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(246, 240, 240);
    width: 160px;
    height: 160px;
    border: 1px solid lightgray;

    img {
      width: auto;
      height: auto;
      max-width: 130px;
      max-height: 130px;
    }
  }
}

.status-yellow {
  border-radius: 2px;
  background: #f3db9e;
  padding: 2px 6px;
  width: fit-content;
  color: #855e13;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}
.status-gray {
  border-radius: 2px;
  background: #e1e1e1;
  padding: 2px 6px;
  width: fit-content;
  color: #6f6f6f;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}
.status-green {
  border-radius: 2px;
  background: #def2d0;
  padding: 2px 6px;
  width: fit-content;
  color: #245900;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}
.status-blue {
  border-radius: 2px;
  background: #dff1f6;
  padding: 2px 6px;
  width: fit-content;
  color: #1995ba;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}
.status-red {
  border-radius: 2px;
  background: #f8e8e8;
  padding: 2px 6px;
  width: fit-content;
  color: #c75e5e;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}
.status-purple {
  border-radius: 2px;
  background: #f5e8fb;
  padding: 2px 6px;
  width: fit-content;
  color: #73398e;
  font-size: var(--font-size-11);
  font-style: normal;
  font-weight: 400;
}

.calendar-wrapper {
  z-index: 2;
  label {
    color: #4c5258;
    font-family: Roboto;
    font-size: var(--font-size-14);
    font-style: normal;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .date-picker-wrapper {
    display: flex;

    .date-icon {
      display: flex;
      align-items: center;
      height: 36px;
      border: 1px solid #ccc;
      border-right: none;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding: 0.375rem 0.75rem;
      padding-right: 2px;
      p {
        font-size: var(--font-size-14);
        font-weight: 500;
        margin-top: 1px;
      }
    }

    input {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: 3px;
      color: #6d7581;
      &:hover {
        border: 1px solid #ccc;
        border-left: none;
      }

      &:focus-visible {
        border: 1px solid #ccc;
        border-left: none;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

/*.search-overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  z-index: 10000;
  background: #00000047;
}*/

.global-search-container {
  width: calc(100% - 400px);
  display: flex;
  align-items: center;
  justify-content: center;
  .search-icon {
    z-index: 10;
    right: 15px;
    top: 9px;
    cursor: pointer;
  }
  .global-search {
    width: 600px !important;
    input {
      border-radius: 4px !important;
    }
    .search-results {
      top: 44px;
      width: 100%;
      min-height: 150px;
      max-height: 60vh;
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      padding: 20px;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
      border-radius: 5px !important;
      .not-found {
        text-align: center;
        .result-not-found {
          font-size: 20px;
        }
        .search-tips {
          margin-top: 20px;
          color: $gray-500;
          font-weight: normal;
        }
      }
    }

    ul {
      list-style: none;
    }

    .level-1 {
      top: 45px;
      max-height: 60vh;
      overflow-y: auto;
      width: 100%;
      overflow-x: hidden;
      padding: 0 10px;
      padding-bottom: 10px;
      left: 0;
      li {
        font-size: 16px;
        font-weight: bold;
      }
      .search-heading {
        margin-top: 12px;
      }
      .tags {
        display: flex;
        gap: 10px;
        margin: 0;
        margin-bottom: 15px;
        li {
          padding: 5px 10px;
          border-radius: 4px;
          cursor: pointer;
          background-color: $gray-300;
          font-size: 13px;
          font-weight: 400;
          border: 1px solid $gray-300;
          &:hover {
            background-color: #ffffff;
            border: 1px solid $gray-300;
          }
        }
      }
    }
    .level-2 {
      margin-top: 10px !important;
      border-top: 2px solid #000000;
      li {
        padding: 10px 5px;
        font-size: 15px;
        font-weight: normal;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        &:hover {
          background-color: $gray-100;
          cursor: pointer;
        }
        .mcont {
          display: flex;
          align-items: center;
          .email {
            font-size: 14px;
            color: $gray-900;
          }
          .tag {
            background: $gray-100;
            padding: 0px 10px;
            color: $gray-900;
            font-size: 13px;
            display: flex;
            align-items: center;
            border-radius: 4px;
          }
          .product-image {
            img {
              margin: 0;
            }
          }
        }
      }
    }
    .data-not-found {
      font-weight: normal !important;
      padding: 10px 0px 0px;
      text-align: center;
      color: $gray-300;
    }
  }
}

.seo-container {
  background: #edf3fc;
  border: 1px solid #000000;
  border-radius: 4px;
  padding: 10px;
  margin: 20px 2px;
}

.seo-title {
  font-size: 18px;
  color: #1a0dab;
  margin-bottom: 1px;
}
.seo-urlhandle {
  color: #006621;
  font-size: 13px;
  margin-bottom: 1px;
}
.seo-description {
  color: #545454;
  font-size: 13px;
}
#hide-input {
  border: none;
  position: absolute;
  top: -10000px;
  &:focus-visible {
    outline-style: none !important;
  }
}

.custom-nav-tabs {
  .nav-link {
    color: #545454;
  }
}

.react-select-loading {
  right: 8px;
  bottom: 4px;
  .spinner-border {
    width: 22px;
    height: 22px;
  }
}

.form-group-custom {
  .unit {
    position: absolute;
    right: 6px;
    top: 9px;
    color: #979797;
    font-size: var(--font-size-12);
  }
}

.input-group {
  .form-select {
    background-color: #ffffff;
  }
}
.add-on-text {
  font-size: var(--font-size-14);
}
.search-add-btn {
  border: 1px solid #d9d9d9;
  width: auto;
  padding: 0px 5px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown-menu-orange {
  background: #f26d24;
  border-radius: 10px;
}

.dropdown-menu .dropdown-item {
  padding: 2px 8px !important;
}

.filter-multi-select {
  width: 200px;
}
.filter-multi-select-100 {
  width: 100%;
}

.multiSelectContainer {
  z-index: 111;
}

.toaster {
  z-index: 11111;
}

.fixed-save-container {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;
  background-color: #1a1a1af0;
  z-index: 1111;
  left: 0;
  text-align: right;
}
.btn {
  box-shadow: none !important;
}
.btn .spinner-border {
  width: 14px;
  height: 14px;
  color: #ffffff;
  border: 2px solid #ffffff;
  border-right-color: transparent;
  &.text-orange {
    border: 2px solid $orange;
    border-right-color: transparent;
  }
  &.text-blue {
    border: 2px solid #0e3c55;
    border-right-color: transparent;
  }
}

.seo-url {
  display: flex;
  position: relative;
  .pre-url {
    z-index: 11;
    font-size: var(--font-size-14);
    background: lightgray;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    padding: 0 10px;
    max-width: fit-content;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .loader {
    position: absolute;
    right: 10px;
    top: 7px;
    z-index: 11;
    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }
}

.form-check-input:disabled {
  background: #f1f2f4 !important;
}

.text-box-length {
  position: absolute;
  right: 0;
  font-size: 13px;
}

.customers-info {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e6ea;
  margin-top: 15px;
  padding-top: 23px;
  padding-bottom: 46px;
  flex-wrap: wrap;
  div {
    // width: 19%;
    @media (max-width: 1148px) {
      width: 30%;
      padding-bottom: 13px;
    }
    @media (max-width: 460px) {
      width: 100%;
    }
    p {
      color: #4c5258;
      font-size: var(--font-size-13);
      font-weight: 400;
    }
    p:first-child {
      font-weight: 600;
      margin-bottom: 9px;
    }
  }
  .customer-credit-balance {
    .credit-points {
      color: #069b15;
      font-size: var(--font-size-15);
      font-weight: 600;
    }
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.damages-list {
  list-style: none;
  .heading {
    font-size: 14px;
    color: #0E3C55;
  }
  ul {
    margin-bottom: 20px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      color: #4C5258;
      width: 33.33%;
      font-size: 12px;
      .form-check {
        margin: 0;
        .form-check-label {
          margin-top: 5px;
          margin-left: 5px;
        }
      }
    }
  }
}

.editable-field {
  display: flex;
  flex: unset;
  gap: 5px;
  flex-direction: column;
  .icon {
    width: 14px;
  }
}

.orange {
  color: #F26D24
}
.blue {
  color: #1895ba;
}
.green {
  color: green;
}
.red {
  color: red;
}

.font-14 {
  font-size: 14px;
  line-height: normal;
}
.font-12 {
  font-size: var(--font-size-12);
  line-height: normal;
}

.dashboard-view {
  background: transparent;
  .row {
    gap: 15px;
    justify-content: center;
    &>div {
      flex: 1;
      background: #ffffff;
      //margin: 20px;
      padding: 20px;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      min-width: 500px;
      max-width: 700px;
    }
  }
  .chart-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .dougnout-wrapper {
    padding: 0 10%;
  }
}

.form-check-input:focus, .form-check-input.focus {
  border: 2px solid #8a93a2 !important;
  box-shadow: 0 0 0 2px rgba(50, 31, 219, 0.25) !important;
}
